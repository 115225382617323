<template>
  <el-container
    style="
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100vh;
    "
  >
    <el-main style="flex-grow: 1; width: 100%">
      <div
        class="content_box"
        style="
          flex-grow: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <h1 style="margin-top: 0px; font-size: 20px; text-align: center">
          安知职业培训线上平台人脸识别课时统计
        </h1>
        <el-table
          :show-header="false"
          :data="userData"
          border
          style="width: 100%; margin-top: 30px"
        >
          <el-table-column prop="date"> </el-table-column>
          <el-table-column prop="name"> </el-table-column>
          <el-table-column prop="time"> </el-table-column>
          <el-table-column prop="type"> </el-table-column>
        </el-table>
        <el-table
          :data="list"
          border
          style="
            width: 100%;
            margin-bottom: 30px;
            border-top-width: 0;
            flex-grow: 1;
          "
        >
          <el-table-column prop="catalogName" label="视频名称" min-width="398">
          </el-table-column>
          <el-table-column
            prop="durationTime"
            label="视频总时长"
            min-width="133"
          >
          </el-table-column>
          <el-table-column
            prop="studyDurationTime"
            label="已学时长"
            min-width="133"
          >
          </el-table-column>
          <el-table-column label="人脸识别" min-width="170">
            <template slot-scope="scope">
              <div style="display: flex">
                <img
                  v-if="scope.row.userFaceOne"
                  :src="scope.row.userFaceOne"
                  style="width: 40px; height: 50px; margin-right: 10px"
                />
                <img
                  v-if="scope.row.userFaceTwo"
                  :src="scope.row.userFaceTwo"
                  style="width: 40px; height: 50px; margin-right: 10px"
                />
                <img
                  v-if="scope.row.userFaceThree"
                  :src="scope.row.userFaceThree"
                  style="width: 40px; height: 50px; margin-right: 10px"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      userData: [],
    };
  },
  beforeCreate() {
    this.$router.push({
      path: "/web/zhengzhouH5/logs2",
      query: {
        json: this.$route.query.json,
      },
    });
  },
  mounted() {
    // this.getApi();
  },
  methods: {
    getApi() {
      this.$post("/biz/heNan/queryH5/queryUserFaceAuthentication", {
        json: this.$route.query.json,
      })
        .then((res) => {
          ;
          this.info = res.data.info;
          this.userData = [
            {
              date: "姓名",
              name: this.info.userName,
              time: "人脸识别",
              type: "正常",
            },
            {
              date: "身份证号",
              name: this.info.idCard,
              time: "联系电话",
              type: this.info.mobile,
            },
            {
              date: "培训工种",
              name: this.info.occupationName,
              time: "学习形式",
              type: "线上",
            },
            {
              date: "所在班级批次",
              name: this.info.projectCode,
              time: "学习所属区(县)主管部门",
              type: this.info.areaName,
            },
            {
              date: "所在班级",
              name: this.info.projectName,
              time: "完成课程折合学时",
              type: this.info.studyLessonNum,
            },
          ];
          this.list = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>